<template>
  <div class="datetime-picker">
    <v-menu>
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="dateText"
          v-on="on"
          v-bind="attrs"
          :dense="dense"
          :label="label"
        />
      </template>
      <v-date-picker
        :first-day-of-week="1"
        flat
        light
        no-title
        v-model="innerValue.date"
        @input="input"
      />
    </v-menu>

    <v-menu>
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="innerValue.time"
          v-on="on"
          :dense="dense"
          v-bind="attrs"
          class="datetime-picker__time"
        />
      </template>
      <v-time-picker
        v-model="innerValue.time"
        format="24hr"
        @input="input"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'

class DateTime {
  constructor (dateTimeString) {
    if (!typeof dateTimeString) throw Error('Argument must be a string')
    const value = moment(dateTimeString)
    this.date = value.format('YYYY-MM-DD')
    this.time = value.format('HH:mm')
  }

  stringify () {
    return moment(`${this.date} ${this.time}`).format()
  }
}

export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
    },
    value: {
      type: String
    }
  },
  data () {
    return {
      innerValue: new DateTime(this.value)
    }
  },
  watch: {
    value (value) {
      this.innerValue = new DateTime(value)
    }
  },
  computed: {
    dateText () {
      return moment(this.innerValue.date).format('DD.MM.YYYY')
    }
  },
  methods:{
    input () {
      this.$emit('input', this.innerValue.stringify())
    }
  }
}
</script>

<style lang="scss" scoped>
.datetime-picker {
  display: flex;
  grid-gap: 8px;
}

.datetime-picker__time {
  flex: 0 0 auto;
  width: 46px
}
</style>
